/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    h4: "h4",
    p: "p",
    strong: "strong",
    em: "em",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "JavaScript Fundamentals"), "\n", React.createElement(_components.h3, null, "Definitions"), "\n", React.createElement(_components.h4, null, "The Stack"), "\n", React.createElement(_components.p, null, "The stack is an ordered data structure. That keeps track of the functions that have been invoked. The stack is modified when a function is invoked."), "\n", React.createElement(_components.p, null, "When a function is invoked the details are saved to the top of the stack. (pushed to the top)"), "\n", React.createElement(_components.p, null, "When a function is returned the information is taken off the top of the stack. (popped of the top)"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "The STACK is processed top to bottom")), "\n", React.createElement(_components.h4, null, "Heap"), "\n", React.createElement(_components.p, null, "The area in memory where data is stored."), "\n", React.createElement(_components.h3, null, "The Queue"), "\n", React.createElement(_components.p, null, "The queue is an ordered list of functions waiting to be placed on the stack.\n", React.createElement(_components.em, null, "First in first out (FIFO)")), "\n", React.createElement(_components.h3, null, "The Event loop"), "\n", React.createElement(_components.p, null, "Functionality in the JavaScript runtime that checks the queue when the stack is empty. If the stack is empty, the front of the queue is placed in the stack."), "\n", React.createElement(_components.h3, null, "JavaScript is Single Threaded"), "\n", React.createElement(_components.p, null, "Single Threaded: Code execution is linear. Code that is running cannot be interrupted by something else going on in the program."), "\n", React.createElement(_components.h2, null, "JavaScript Classes"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes"
  }, "Classes in JavaScript - MDN Web Docs")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
